<template>
	<div class="content">
		<div v-if="!loading && !error" class="inner-content">
			<div class="with-image">
				<img :src="require(`../../assets/dashboard-icons/wallet.svg`)" alt="wallet-image" class="image" />
				<div class="vertical-content">
					<span class="label">{{ $t('wallets.wallet-id') }}</span>
					<span>{{ walletId }}</span>
				</div>
			</div>
			<div class="vertical-content">
				<span class="label">{{ $t('dashboard.current-balance') }}</span>
				<span class="bold">{{
					Intl.NumberFormat($i18n.locale, { style: 'currency', currency: currency ?? 'USD' }).format(
						Number(balance) + Number(credit)
					)
				}}</span>
			</div>
		</div>
		<div v-if="error" class="error">{{ $t(error) }}</div>
		<div v-if="loading" class="non-wallets-info">
			<img class="rotate" :src="require('../../assets/icons/pending-2.svg')" />
		</div>
	</div>
</template>

<script>
import apiClient from '../../api';

export default {
	name: 'PaymentWallet',
	data() {
		return {
			balance: 0,
			credit: 0,
			currency: null,
			walletId: null,
			loading: false,
			error: null,
		};
	},
	async mounted() {
		this.walletId = this.$route.params.walletId;
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				this.loading = true;
				const { currency, balance, credit } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.currency = currency;
				this.balance = balance;
				this.credit = credit;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.content {
	background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%),
		linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding: 16px 16px 16px 16px;
	border: 1px solid #ffffff1a;
}

.inner-content {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.vertical-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.with-image {
	display: flex;
	align-items: center;
	gap: 10px;
}

.error {
	color: $error-pink;
}

.label {
	color: #ffffff;
	opacity: 0.5;
	font-size: $label-sm;
}

.bold {
	font-weight: bold;
}

.image {
	width: 40px;
}

.rotate {
	animation: rotation 2s infinite linear;
	width: 50px;
	height: 50px;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>
